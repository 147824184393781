<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center " md="6" lg="6" xl="6">
                        <div class="font-weight-bold body-1">
                            Detalles del banco
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="6" xl="6" >

                        <details-item :detailsItems="items" data-cy="data-details-items"></details-item>


                    </v-col>
                    
                </v-row>

                <v-row justify="center" v-if="transactions.length != 0">
                    <v-col cols="12" md="6" lg="6" xl="6" class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">Transacciones</p>
                    </v-col>
                </v-row>

               <v-row justify="center" v-for="(transaction, index) in transactions" :key="index">
                    <v-col cols="12" md="6" lg="6" xl="6" >
                        <v-row justify="center" no-gutters>
                            <v-col class="my-0 py-0">
                                <div >
                                    <v-row justify="center" align="center" no-gutters class="my-2">
                                       <!-- <div  class=""> -->
                                            <v-col align-self="center" class="mb-0"  >
                                                <span class="font-weight-bold normalText--text " data-cy="data-details-category-item">
                                                    {{ transaction.category == "Deposit" ? "Depósito": "Retiro" }} 
                                                    <v-icon v-if="transaction.category == 'Deposit'" x-small color="green">mdi-arrow-bottom-right</v-icon> 
                                                    <v-icon v-else x-small color="error">mdi-arrow-top-right</v-icon> 
                                                </span>
                                            </v-col>
                                            <!-- <v-icon color="primary">mdi-pencil</v-icon> -->
                                       <!-- </div> -->
                                        <v-col class="text-right">
                                            <v-btn data-cy="data-details-edit-link" icon text :to="{ name: 'BanksAccountsEditTransaction', params: { id: id, transaction_id:transaction.id} }" >
                                                <v-icon color="primary">mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn data-cy="data-details-delete-link" icon text target="_blank" @click="openDeleteModal(transaction)" >
                                                <v-icon color="primary" >mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                        
                                    </v-row>
                                    <v-divider></v-divider>
                                </div>
                            </v-col>
                        </v-row>

                        <details-item :detailsItems="getTransactionItem(transaction)" data-cy="data-transaction-details-items"></details-item>


                    </v-col>
                    
                </v-row>
               
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" lg="6" xl="6" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>

            <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="index-modal-actions">
                        <v-col cols="12" sm="4">
                            <secondaryButton
                            data-cy="cancel-modal-btn"
                            :props="cancelModal"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            data-cy="accept-modal-btn"
                            :props="acceptModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            transactions: [],
            items: [
               
                
            ],

            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "BanksAccounts",
                block:false,
                click: () => {}
            },


            modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                cy:"cancel-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                cy:"accept-modal-btn",
                click: ()=>{this.delete()}
            },
            
          
          
		}
    },
    mounted(){
        this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.cancelBtnData.block = true;
                
            }
            else{
                this.cancelBtnData.block = false;
                
            }
        },
		
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.bankAccount.get(this.id)
			.then((response) => {
                console.log(response);
                
               
                this.items= [
                    { concepto: 'Alias', index: 'prospect', valor:response.data.alias_name},
                    { concepto: 'Tipo', index: 'description', valor:response.data.category == "Credit" ? "Crédito" : "Débito"},
                    { concepto: 'Banco', index: 'project_name', valor:response.data.bank.name},
                    { concepto: 'Número de cuenta', index: 'validity', valor:response.data.account_number},
                    { concepto: 'Saldo inicial', index: 'foreign_exchange', valor: Utils.currencyFormat(response.data.initial_balance, "") },
                    { concepto: 'Fecha de Saldo inicial', index: 'foreign_exchange', valor: Utils.globalDateFormat(response.data.initial_balance_date) },
                    { concepto: 'Saldo', index: 'foreign_exchange', cy:"balance-detail-item", valor:Utils.currencyFormat( response.data.balance, "") },
                    
                ];

                this.transactions = response.data.transactions;

            
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},

        getTransactionItem(transaction)
        {
            return [
                { concepto: 'Fecha', index: 'prospect', valor:transaction.date},
                { concepto: 'Monto', index: 'description', valor: Utils.currencyFormat(transaction.amount, "")},
                { concepto: 'Descripción', index: 'project_name', valor: transaction.description},
                { concepto: 'Comprobante', index: 'project_name', valor: transaction.receipt_url, isFile: true},
                
            ];
        },

        openDeleteModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.date}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.bankAccount.deleteTransaction(this.id, this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 